.SaveOpticianForm,
.OpticianList {
    color: #3982f7;
}

.SaveOpticianDialog h1,
.OpticianList h1 {
    /*margin-top: 0px;*/
    font-size: 3.2em;
    font-weight: bold;
    color: #3982f7;
}

.SaveOpticianDialog h2,
.OpticianList h2 {
    font-size: 1.92em;
    font-weight: bold;
    color: #3982f7;
}

.SaveOpticianDialog h3,
.OpticianList h3 {
    font-size: 1.92em;
    font-weight: bold;
    color: #3982f7;
}

.SaveOpticianForm .btn,
.OpticianList .btn,
.SaveOpticianForm input[type='submit'] {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 20px;
    font-size: 1.3em;
    font-weight: bold;
    background-color: #3982f7;
}

.SaveOpticianForm .btn-default,
.OpticianList .btn-default,
.SaveOpticianForm input[type='submit'] {
    background-color: #3982f7;
    color: #fff;
}

.SaveOpticianForm .btn-danger,
.OpticianList .btn-danger {
    background-color: #f07777;
    color: #fff;
}

.SaveOpticianForm input,
.SaveOpticianForm textarea,
.SaveOpticianForm select
{
    border-color: rgba(57,130,247,0.5);
    color: #3462f7;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(57,130,247,0.65) !important;
    opacity: 1 !important; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(57,130,247,0.65) !important;;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(57,130,247,0.65) !important;;
}
