.SessionMenuItem p {
    /*color: white;*/
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    /*color: #333;*/
    white-space: nowrap;
}

.SessionMenuItem.dropdown.open {
    background-color: #3982f7;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        background-color: #3876DB;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #2862B3;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #DCDCDC;
    }
    .SessionMenuItem p {
        color: #DCDCDC;
    }
}
.navbar-nav .open .dropdown-menu {
    color: #050505;
}

/*.SessionMenuItem a {*/
    /*color: white;*/
/*}*/

