
.Home {
}

.Home img {
    right: 0px;
    max-width: 100%;
    max-height: 100%;
}

.Home .col-xs-12 {
    padding-bottom: 10px;
}

.Home .illustrationContainer {
    padding-top: 37px;
}

@media (max-width: 991px) {
    .Home .illustrationContainer {
        padding-top: 0px;
    }
}

.Home .jumbotron {
    background-color: #3982f7;
    border-radius: 0;
    height: 22em;
}

.Home .jumbotron .btn {
    border: 1px solid #ffffff;
    color: #ffffff;
    font-weight: bold;
    border-radius: 3px;
    padding: 7.5px 18px;
    position: absolute;
    top: 44px;
    right: 50px;
}

@media (max-width: 1200px) {
    .Home .jumbotron .btn {
        top: 20px;
    }
}

.Home .jumbotron.hangDown {
    background-color: #3982f7;
    border-radius: 0;
    margin-top: 18px;
}

.Home .jumbotron.hangDown .btn {
    top: 62px;
    right: 50px;
}

@media (max-width: 1200px) {
    .Home .jumbotron.hangDown .btn {
        top: 38px;
    }
}


.Home .jumbotron .startingLine {
    border-top: 1px solid;
    width: 90px;
    display: block;
}
.Home .jumbotron .startingLine {
    border-top: 1px solid #ffffff;
    height: 1.0em;
}
.Home .jumbotron.white .startingLine {
    border-top: 2px solid #3982f7;
}

.Home .jumbotron.white {
    background-color: #ffffff;
    margin-bottom: 0px;
    padding: 0;
    height: auto;
}
@media (max-width: 991px) {
    .Home .jumbotron.white {
        margin-bottom: 15px;
    }
}

.Home .jumbotron.white a {
    text-decoration: underline;
}

.Home .jumbotron.white.welcome {
    padding: 70px 0px 0px 0px;
}

.Home .jumbotron.white.welcome h2 {
    font-size: 3em;
    /*font-weight: bold;*/
    /*color: #ffffff;*/
}

.Home .jumbotron.white.welcome p {
    font-size: 1.7em;
    /*font-weight: normal;*/
    /*color: #ffffff;*/
    /*line-height: 1.2em;*/
}

.Home .jumbotron.white * {
    color: #3982f7;
    background-color: #ffffff;
}

.Home .jumbotron.shadow {
    box-shadow: 3px 10px 30px 5px rgba(200,200,200, 1);
}

.Home .jumbotron h1 {
    font-size: 3.2em;
    font-weight: bold;
    color: #ffffff;
}

.Home .jumbotron h2 {
    font-size: 1.92em;
    font-weight: bold;
    color: #ffffff;
}

.Home .jumbotron p {
    font-size: 1.5em;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.2em;
}