.Menu {
    color: #ffffff;
    background-color: #3982f7;
    font-family: Arial;
    font-weight: bold;
}

.Menu .navbar-header {
    height: 62px;
}

.Menu .navbar-inverse {
    padding: 3px;
}
@media (max-width: 768px) {
    .Menu .navbar-inverse {
        padding: 3px 10px 10px 10px;
    }
    .Menu .navbar-header {
        padding-bottom: 5px;
    }
}

.Menu .navbar-inverse .navbar-nav>li>a:visited {
    color: #ffffff;
}

/* The collapsed menu button */
.Menu .navbar-toggle {
    margin-top: 15px;
    border-color: #ffffff;
}

.Menu .navbar-toggle:hover, .Menu .navbar-toggle:focus {
    background-color: #2068dc;
}

.Menu .btn-default {
    color: #3982f7;
    background-color: #fff;
    border-color: #ccc;
    border-radius: 3px;
}

.Menu .navbar {
    background-color: #3982f7;
    border: none;
}

.Menu .navbar-brand {
    padding-top: 20px;
    padding-bottom: 10px;
}

.Menu .navbar-collapse, .collapse {
    border-color: #2972e7;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    /*margin-top: auto;*/
    /*margin-bottom: auto;*/
}

.Menu
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    background-color: #3982f7;
}
.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: #3982f7;
}


.Menu .navbar-nav>li>a{
    color: white;
    margin-top: 5px;
    /*padding-top: 0px;*/
    /*padding-bottom: 0px;*/
}
.Menu .navbar-nav>li>a:not([role=button]){
    font-size: 1.67em;
}

.Menu .navbar-nav>li>a>button {
    margin-top: -5px;
    padding-top: 5px;
    padding-bottom: 5px;
    /*font-size: 1.3em;*/
    /*font-weight: bold;*/
}