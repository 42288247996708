.Referral {
    color: #3982f7;
    background-color: #ffffff;
}

.panel-default {
    border: solid rgba(57,130,247,0.4) 1px;
    padding: 4px;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
/*input[type="date"]:after {*/
/*    content: "\25BC";*/
/*    !*content: "_";*!*/
/*    !*background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%233982f7" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');*!*/
/*    color: rgba(57,130,247,0.4);*/
/*    padding: 0 5px;*/
/*}*/
/* change color of symbol on hover */
input[type="date"]:hover:after {
    color: #3982f7;
}
::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%233982f7" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    /*background: transparent;*/
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    cursor: pointer;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
    z-index: 1;
}


/* --- DATEPICKER --- */
.Referral .datePickerCalendar div.date-picker-popover {
    top: 34px !important;
    left: 25px !important;
}
.Referral .datePickerCalendar div.date-picker-popover div.arrow {
    left: 52% !important;
}

@media (max-width: 425px) {
    .Referral .datePickerCalendar div.date-picker-popover {
        top: 34px !important;
        left: -80px !important;
    }
    .Referral .datePickerCalendar div.date-picker-popover div.arrow {
        left: 53% !important;
    }
}

@media (max-width: 600px) {
    .Referral .datePickerCalendar div.date-picker-popover {
        top: 34px !important;
        left: -60px !important;
    }
    .Referral .datePickerCalendar div.date-picker-popover div.arrow {
        left: 53% !important;
    }
}
/* --- DATEPICKER --- */


/* --- EXAMINATION RESULT FORM --- */
.Referral div.referralValueCol {
    padding-left: 7px;
    padding-right: 7px;
}

@media (min-width: 992px) {
    .Referral .fatBorderColumn {
        border-left: solid rgba(57,130,247,0.3) 1.5px;
    }
    .Referral .fatBorderColumn .row {
        padding-left: 15px;
    }
    .panel-default {
        margin-left: 15px;
    }
}
@media (max-width: 991px) {

    .Referral .senderReceiver div {
        /*padding-top: 15px;*/
    }
    .Referral div.well {
        margin-top: 15px;
    }
    .panel-default {
        padding: 1px;
    }
    .resultHeader {
        font-size: 0.8em;
    }
}
@media (max-width: 425px) {

    .Referral .container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .Referral .fatBorderColumn {
        padding-left: 2px;
        padding-right: 2px;
    }

    .resultHeader {
        font-size: 0.65em;
    }
}

@media (max-width: 800px) {
    .Referral .resultMatrix .form-control {
        padding: 6px 1px;
    }
}

.Referral .resultMatrix .form-control {
    padding: 6px 8px;
}

.Referral .borderColumn {
    border-left: solid rgba(57,130,247,1) 1px;
}
/* --- EXAMINATION RESULT FORM --- */




.Referral input,
.Referral textarea,
.Referral select
{
    border-color: rgba(57,130,247,0.5);
    color: #3462f7;
}

.Referral .form-group div {
    padding-left: 0px;
}
.Referral select {
    margin-top: 12px;
}
.Referral .date {
    margin-bottom: 8px;
}
.Referral .control-label {
    padding-top: 8px;
}

@media (min-width: 992px) {
    .Referral select {
        margin-left: -24px;
    }
    .Referral .senderReceiver select {
        margin-left: 0px;
    }
}

.Referral input[type='radio'] {
    margin-top: 0px;
}

.Referral .btn {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 10px;
    border-radius: 20px;
}

.Referral Button {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 10px;
    border-radius: 20px;
}

.Referral .btn.download {
    background-color: #55a74b;
    border-color: #1dbc00;
}
.Referral Button.send {
    background-color: #3982f7;
}
.Referral Button.cancel {
    background-color: #f07777;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(57,130,247,0.65) !important;
    opacity: 1 !important; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(57,130,247,0.65) !important;;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(57,130,247,0.65) !important;;
}