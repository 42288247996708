.SaveCareUnitForm,
.CareUnitList {
    color: #3982f7;
}

.SaveCareUnitDialog h1,
.CareUnitList h1 {
    /*margin-top: 0px;*/
    font-size: 3.2em;
    font-weight: bold;
    color: #3982f7;
}

.SaveCareUnitDialog h2,
.CareUnitList h2 {
    font-size: 1.92em;
    font-weight: bold;
    color: #3982f7;
}

.SaveCareUnitDialog h3,
.CareUnitList h3 {
    font-size: 1.92em;
    font-weight: bold;
    color: #3982f7;
}

.SaveCareUnitForm .btn,
.CareUnitList .btn,
.SaveCareUnitForm input[type='submit'] {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 20px;
    font-size: 1.3em;
    font-weight: bold;
    background-color: #3982f7;
}

.SaveCareUnitForm .btn-default,
.CareUnitList .btn-default,
.SaveCareUnitForm input[type='submit'] {
    background-color: #3982f7;
    color: #fff;
}

.SaveCareUnitForm .btn-danger,
.CareUnitList .btn-danger {
    background-color: #f07777;
    color: #fff;
}

.SaveCareUnitForm input,
.SaveCareUnitForm textarea,
.SaveCareUnitForm select
{
    border-color: rgba(57,130,247,0.5);
    color: #3462f7;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(57,130,247,0.65) !important;
    opacity: 1 !important; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(57,130,247,0.65) !important;;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(57,130,247,0.65) !important;;
}
