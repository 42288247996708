.Footer .footerContent {
    border-top: 1px solid #3982f7;
    color: #3982f7;
    font-family: Arial;
    padding-top: 40px;
    padding-bottom: 60px;
    margin-top: 40px;
}

.Footer .footerContent span:not(.footerLogo) {
    font-weight: normal;
    font-size: 1.16em;
    padding-top: 1.16em;
    display: block;
}

@media (min-width: 991px) {
    .Footer .footerContent .footerCopyright {
        text-align: right;
        width: 100%;
    }
}
@media (max-width: 991px) {
    .Footer .footerContent .footerCopyright {
        margin-bottom: 60px;
    }
}

.Footer .footerContent .footerLogo {
    font-weight: bold;
    font-size: 2.4em;
    letter-spacing: -2px;
    padding-right: 40px;
}

.Footer .footerContent a {
    color: #3982f7;
}