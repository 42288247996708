body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  font-face: Arial;
  font-size: 1.3em;
  font-weight: bold;
}

h3 {
  color: #3982f7;
}


/*.SaveCareUnitForm,*/
/*.CareUnitList {*/
  /*color: #3982f7;*/
/*}*/

/*.SaveCareUnitDialog h1,*/
/*.CareUnitList h1 {*/
  /*!*margin-top: 0px;*!*/
  /*font-size: 3.2em;*/
  /*font-weight: bold;*/
  /*color: #3982f7;*/
/*}*/

/*.SaveCareUnitDialog h2,*/
/*.CareUnitList h2 {*/
  /*font-size: 1.92em;*/
  /*font-weight: bold;*/
  /*color: #3982f7;*/
/*}*/

/*.SaveCareUnitDialog h3,*/
/*.CareUnitList h3 {*/
  /*font-size: 1.92em;*/
  /*font-weight: bold;*/
  /*color: #3982f7;*/
/*}*/

/*.SaveCareUnitForm .btn,*/
/*.CareUnitList .btn {*/
  /*padding-left: 16px;*/
  /*padding-right: 16px;*/
  /*border-radius: 20px;*/
/*}*/

/*.SaveCareUnitForm .btn-default,*/
/*.CareUnitList .btn-default {*/
  /*background-color: #3982f7;*/
  /*color: #fff;*/
/*}*/

/*.SaveCareUnitForm .btn-danger,*/
/*.CareUnitList .btn-danger {*/
  /*background-color: #f07777;*/
  /*color: #fff;*/
/*}*/

/*.SaveCareUnitForm input,*/
/*.SaveCareUnitForm textarea,*/
/*.SaveCareUnitForm select*/
/*{*/
  /*border-color: rgba(57,130,247,0.5);*/
  /*color: #3462f7;*/
/*}*/

/*::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!*/
  /*color: rgba(57,130,247,0.65) !important;*/
  /*opacity: 1 !important; !* Firefox *!*/
/*}*/

/*input:-ms-input-placeholder { !* Internet Explorer 10-11 *!*/
  /*color: rgba(57,130,247,0.65) !important;;*/
/*}*/

/*input::-ms-input-placeholder { !* Microsoft Edge *!*/
  /*color: rgba(57,130,247,0.65) !important;;*/
/*}*/